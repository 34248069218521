import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Heading,
  CardHeader,
  Card,
  CardBody,
  InputRightElement,
  Input,
  InputGroup,
  Image,
  useColorMode,
  useToast,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/slices/auth';
import { getMyStore } from '../redux/slices/store';

import CategorySvg from '../assets/svg/category.svg';
import ProductSvg from '../assets/svg/products.svg';
import ProfileSvg from '../assets/svg/store-profile.svg';
import CommunitySvg from '../assets/svg/store-community.svg';

import PaymentSvg from '../assets/svg/payment.svg';
import OrderSvg from '../assets/svg/order.svg';
import WithdrawSvg from '../assets/svg/withdraw.svg';

import LanguageSvg from '../assets/svg/language.svg';
import NewsSvg from '../assets/svg/news.svg';
import FaqSvg from '../assets/svg/faq.svg';
import SupportSvg from '../assets/svg/support.svg';

import ShareSvg from '../assets/svg/share-button.svg';
import EditSvg from '../assets/svg/edit-button.svg';

import { FaChevronRight } from 'react-icons/fa';
import { toggleDarkMode } from '../redux/slices/settings';

import { RiMoonClearFill } from 'react-icons/ri';
import { FiSun } from 'react-icons/fi';
import { getShareLink } from '../helpers/connection';

export default function Dashboard() {
  let startParam = window.Telegram.WebApp.initDataUnsafe.start_param;

  let chatInfo = window.Telegram.WebApp.initDataUnsafe.user;

  const toast = useToast();

  const { colorMode, toggleColorMode } = useColorMode();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const authState = useSelector(state => state.auth);

  const storeState = useSelector(state => state.store);

  const [storeUrl, setStoreUrl] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(true);

  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  // console.log(window.location.origin);

  const query = useQuery();
  const startApp = query.get('startapp');

  const navigateToStore = storeId => {
    navigate(`/store/${storeId}`);

    return;
  };

  const getUpdateUserToken = () => {
    if (!chatInfo?.id) {
      return { email: '', password: '', name: '' };
    }
    const input = chatInfo?.id + '-' + chatInfo?.username;

    //const input = '861650732-Raphael';

    const result = input.split(/-/);

    const email = 'bot' + result[0] + '@telegram.com';

    const password = result[0] + 'botPass';

    return { email, password };
  };

  const handleClick = async () => {
    await navigator.clipboard.writeText(storeUrl);

    toast({
      position: 'top-right',
      render: () => (
        <Box color="white" p={3} bg="blue.500">
          Copied
        </Box>
      ),
    });
  };

  const getIdFromPathname = pathname => {
    const parts = pathname.split('/');

    if (parts[1]) {
      return parts[1];
    }
  };

  React.useEffect(() => {
    if (!chatInfo?.id) {
      navigate(`/notfound`);
      return;
    }

    if (startParam) {
      const result = startParam.split('-');

      if (
        result[0] == 'store' &&
        getIdFromPathname(location.pathname) !== 'dashboard'
      ) {
        // alert(`${getIdFromPathname(location.pathname)}`);

        const id = startParam.split('-').slice(1).join('-');
        navigateToStore(id);
        return;
      }
    }

    tryToLogin();

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const tryToLogin = () => {
    const callBackLogin = response => {
      if (response?.status_code == 422) {
        navigate('/create-store');
        return;
      }
      if (response.status_code === 200) {
        dispatch(getMyStore());
      }
    };
    const { email, password } = getUpdateUserToken();

    dispatch(
      login({
        email,
        password,
        callback: callBackLogin,
      })
    );
  };

  React.useEffect(() => {
    if (authState.token) {
      if (storeState.myStores.length > 0) {
        const botLink = getShareLink();
        // `https://raph-boot-test.netlify.app/store/${storeState?.myStores[0]?.id}`
        setStoreUrl(`${botLink}${storeState?.myStores[0]?.id}`);
      } else {
        navigate('/create-store');
      }
    }
  }, [storeState.myStores, authState.token]);

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent={'center'}
        // pt={{ base: '120px', md: '75px' }}
        // px={{ base: '20px', md: '20px' }}
      >
        <Card
          p="10px"
          // bgColor={bg}
          h={'50px'}
          borderRadius={0}
          //   bgColor={'gray.100'}
          w="100%"
          alignSelf={'center'}
        >
          <Flex direction={'row'} justifyContent={'space-between'}>
            <Button
              onClick={() => navigate(`/`)}
              variant="ghost"
              alignSelf={'flex-start'}
              _hover={{ bg: 'transparent' }}
            >
              <Text fontSize={'16px'}>NOTSTORE</Text>
            </Button>

            <Box
              alignSelf={'center'}
              pr={'10px'}
              onClick={() => {
                dispatch(toggleDarkMode());
                toggleColorMode();
              }}
            >
              {colorMode == 'dark' ? (
                <FiSun />
              ) : (
                <RiMoonClearFill color="black" />
              )}
            </Box>
          </Flex>
        </Card>

        <Flex direction={'column'} px={{ base: '20px', md: '20px' }} mb={4}>
          <Flex
            alignItems={'center'}
            my={4}
            direction={'row'}
            justifyContent={'space-between'}
          >
            {isLoading ? (
              <Skeleton
                noOfLines={1}
                mb={2}
                spacing="4"
                height={'30px'}
                width="150px"
              />
            ) : (
              <Heading textTransform="uppercase" pt={3} size={'sm'}>
                {storeState.myStores && storeState.myStores.length > 0
                  ? storeState.myStores[0].store_name
                  : 'Proceed to create a store....'}
              </Heading>
            )}
          </Flex>
          <Flex alignItems={'center'} mt={2} mb={5} direction={'row'}>
            <Image
              onClick={handleClick}
              src={colorMode == 'light' ? ShareSvg : ShareSvg}
              mr={4}
            />

            {/* <Image src={colorMode == 'light' ? EditSvg : EditSvg} /> */}
          </Flex>

          {isLoading ? (
            <SkeletonText noOfLines={4} spacing="4" />
          ) : (
            <Text
              noOfLines={4}
              fontSize={'14px'}
              lineHeight={'19.6px'}
              fontWeight={'400'}
            >
              {storeState.myStores && storeState.myStores.length > 0
                ? storeState.myStores[0].store_description
                : 'We sell different products, process orders quickly and urgent delivery, We sell different products, process orders quickly and urgent delivery, We sell different products, process orders quickly and urgent delivery, We sell different products, process orders quickly and urgent delivery'}
            </Text>
          )}
        </Flex>

        {(storeState?.myStores ?? []).length > 0 && (
          <Grid
            templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} // 1 column on mobile, 3 columns on medium screens and above
            gap={{ base: 4, md: 8 }} // Spacing between items, 4 on mobile, 8 on medium screens and above
            // Other grid properties as needed
            pt={{ base: '30px', md: '20px' }}
            px={{ base: '20px', md: '20px' }}
            pb={{ base: '30px', md: '20px' }}
          >
            <Card>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    onClick={() => navigate(`/upload-product`)}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <Image src={CategorySvg} mr={3} />
                      <Heading
                        size="xs"
                        color={
                          colorMode == 'light'
                            ? 'rgba(52, 75, 103, 1)'
                            : 'white'
                        }
                        textTransform="uppercase"
                      >
                        Create Product
                      </Heading>
                    </Box>

                    <FaChevronRight />
                  </Flex>

                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      width={'100%'}
                      onClick={() => navigate('/products')}
                    >
                      <Image src={ProductSvg} mr={3} />
                      <Heading
                        size="xs"
                        color={
                          colorMode == 'light'
                            ? 'rgba(52, 75, 103, 1)'
                            : 'white'
                        }
                        textTransform="uppercase"
                      >
                        Products
                      </Heading>
                    </Box>

                    <FaChevronRight />
                  </Flex>

                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    onClick={() => navigate('/store-orders')}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <Image src={OrderSvg} mr={3} />
                      <Heading
                        size="xs"
                        color={
                          colorMode == 'light'
                            ? 'rgba(52, 75, 103, 1)'
                            : 'white'
                        }
                        textTransform="uppercase"
                      >
                        Order History
                      </Heading>
                    </Box>
                    <FaChevronRight />
                  </Flex>

                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    onClick={() => navigate('/faq')}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <Image src={FaqSvg} mr={3} />
                      <Heading
                        size="xs"
                        color={
                          colorMode == 'light'
                            ? 'rgba(52, 75, 103, 1)'
                            : 'white'
                        }
                        textTransform="uppercase"
                      >
                        Notstore FAQ
                      </Heading>
                    </Box>
                    <FaChevronRight />
                  </Flex>

                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    onClick={() => {
                      const telegramUrl = `https://x.com/thenotstore?s=11&t=dOo0WMyKkIsRuW3OvJqYuw`;
                      window.Telegram.WebApp.openLink(telegramUrl);
                    }}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <Image src={SupportSvg} mr={3} />
                      <Heading
                        size="xs"
                        color={
                          colorMode == 'light'
                            ? 'rgba(52, 75, 103, 1)'
                            : 'white'
                        }
                        textTransform="uppercase"
                      >
                        Contact Support
                      </Heading>
                    </Box>

                    <FaChevronRight />
                  </Flex>
                </Stack>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'100%'}
                    onClick={() => navigate(`/store-profile`)}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      <Image src={ProfileSvg} mr={3} />
                      <Heading
                        size="xs"
                        color={
                          colorMode == 'light'
                            ? 'rgba(52, 75, 103, 1)'
                            : 'white'
                        }
                        textTransform="uppercase"
                      >
                        Profile
                      </Heading>
                    </Box>

                    <FaChevronRight />
                  </Flex>

                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      width={'100%'}
                      onClick={() => navigate('/store-community')}
                    >
                      <Image src={CommunitySvg} mr={3} />
                      <Heading
                        size="xs"
                        color={
                          colorMode == 'light'
                            ? 'rgba(52, 75, 103, 1)'
                            : 'white'
                        }
                        textTransform="uppercase"
                      >
                        Community
                      </Heading>
                    </Box>

                    <FaChevronRight />
                  </Flex>

                  <Flex
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      width={'100%'}
                      onClick={() => navigate('/all-withdrawals')}
                    >
                      <Image src={WithdrawSvg} mr={3} />
                      <Heading
                        size="xs"
                        color={
                          colorMode == 'light'
                            ? 'rgba(52, 75, 103, 1)'
                            : 'white'
                        }
                        textTransform="uppercase"
                      >
                        Withdrawals
                      </Heading>
                    </Box>

                    <FaChevronRight />
                  </Flex>
                </Stack>
              </CardBody>
            </Card>
          </Grid>
        )}
      </Flex>
    </>
  );
}
